<template>
  <div class="modal-content">
    <div class="box">
      <div class="columns is-mobile">
        <div class="column">
          <p class="subtitle has-text-primary">Switch branch</p>
        </div>
        <div class="column is-narrow">
          <a class="has-text-danger" @click="$modal.close">Close</a>
        </div>
      </div>
      <div class="container is-narrow">
        <div v-if="availableBranches.length === 1">
          <TableEmpty size="small" text="You only belong to one branch" />
        </div>
        <form
          @submit.prevent="confirm"
          v-else-if="availableBranches.length > 1"
        >
          <div class="field">
            <p class="subtitle is-6">
              From the options below, select the branch you wish you switch to
            </p>
            <div class="field">
              <label class="label is-size-7">Branch</label>
              <div class="control" data-test-id="switch-branch-select">
                <MultiSelect
                  v-model="branch"
                  :options="availableBranches"
                  :disabled="!availableBranches.length"
                  placeholder="Select or start typing"
                  :show-labels="false"
                  :hide-selected="true"
                  :allow-empty="false"
                  label="name"
                  track-by="id"
                >
                  <span slot="noResult">No branches matching your search</span>
                </MultiSelect>
              </div>
            </div>
          </div>
          <div class="buttons is-right">
            <button
              type="submit"
              class="button is-soft is-rounded"
              :disabled="$wait.is('branches')"
              :class="{ 'is-loading': $wait.is('branches') }"
            >
              Switch
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getBranches } from 'modules/account/services'
import { confirmBranch } from 'modules/login/services/login.service'
import { checkTerms } from 'modules/auth/services'
import { logOut } from 'modules/auth/services'

export default {
  name: 'AccountSwitch',
  components: {
    MultiSelect: () => import('vue-multiselect'),
    TableEmpty: () => import('core/components/TableEmpty')
  },
  data: () => ({ branch: {}, branches: [] }),
  mounted() {
    this.branch = this.currentBranch
    this.fetch()
  },
  computed: {
    ...mapGetters({ currentBranch: 'auth/branch', token: 'auth/token' }),
    availableBranches() {
      return this.branches?.filter(branch => branch.type !== 'experian') || []
    }
  },
  methods: {
    async fetch() {
      try {
        this.$wait.start('branches')
        this.branches = await getBranches()
      } catch (err) {
        this.$notify('Branches unavailable')
      } finally {
        this.$wait.end('branches')
      }
    },
    async confirm() {
      this.$mxp.track('branch_switch_request')
      try {
        this.$wait.start('branches')
        const params = { orgId: this.branch.id, token: this.token }
        const { jwt } = await confirmBranch(params)
        await this.complete({ jwt })
        this.$mxp.track('branch_switch_success')
      } catch (err) {
        this.$mxp.track('branch_switch_failed')
        const fallback = "We're currently unable to switch your branch"
        this.error = err?.response?.data?.error?.message ?? fallback
        this.$notify(this.error)
      } finally {
        this.$wait.end('branches')
      }
    },
    async complete({ jwt }) {
      this.$store.commit('auth/setToken', jwt)
      try {
        await this.$store.dispatch('auth/getUser')
        
        const isStatic = this.$store.getters['auth/isIntegration']
        if (isStatic) {
          logOut()
          return
        } else if (this.$route.name !== 'root') {
          this.$router.push({ name: 'root' }).catch(() => {})
        }
        
        this.$notify('Branch switched')
        this.$modal.close()
        checkTerms()
      } catch (err) {
        const text = 'Something went wrong during authentication'
        this.$notify({ group: 'danger', text })
      }
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="sass">
.multiselect
  min-width: 16rem
  max-width: 100%
  .multiselect__option--selected
    background: #ccc
    color: #fff
    &.multiselect__option--highlight
      background: #ccc
      cursor: not-allowed
.multiselect__option
  &.multiselect__option--highlight
    background: $primary
.multiselect__content-wrapper
  position: relative
.multiselect__single
  cursor: pointer
</style>
